const stylesheet = new CSSStyleSheet();stylesheet.replaceSync(`.head,
.attachment {
  padding: 0.2rem 0;
}

.head {
  font-size: 0;
}

.head > * {
  display: inline-block;
  font-size: initial;
  vertical-align: top;
}

.info {
  width: 90%;
}

.info > * {
  display: inline-block;
  padding: 0 0.2rem;
  word-break: break-all;
}

.avatar {
  border-radius: 50%;
  width: 10%;
}

.username {
  font-weight: bold;
}

.username,
.text {
  color: var(--text);
}

.text {
  display: block;
  margin: 0.5rem 0;
  white-space: pre-line;
}

.attachment {
  position: relative;
  width: 100%;
}

.attachment > * {
  border-radius: 2rem;
  width: 100%;
}

iframe {
  aspect-ratio: 16 / 9;
  border: 0;
}

component-post-tooltip {
  position: absolute;
  transform: translate(-50%, -125%);
}

@media screen and (max-width: 768px) {
  component-post-tooltip {
    display: none !important;
  }
}

.hashtag {
  background: var(--surface2);
  border-radius: 2rem;
  color: var(--text);
  padding: 0.5rem;
  white-space: nowrap;
  width: fit-content;
}
`);export default stylesheet;