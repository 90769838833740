const stylesheet = new CSSStyleSheet();stylesheet.replaceSync(`a {
  color: var(--blue);
  text-decoration: none;
}

li {
  list-style: none;
  padding: 0.5rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}

.column {
  display: inline-block;
  font-size: initial;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
}
`);export default stylesheet;