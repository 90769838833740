const stylesheet = new CSSStyleSheet();stylesheet.replaceSync(`.fragment {
  font-size: 0;
  padding: 1rem;
}

.column {
  vertical-align: middle;
  width: 50%;
}

.title {
  background-image: linear-gradient(120deg, var(--peach), var(--mauve));
  background-clip: text;
  color: transparent;
}

[data-block="s-1"] {
  text-align: right;
}
`);export default stylesheet;